import logo from "../../assets/logo.png"
import React, { useEffect, useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import {Avatar, Badge, Popover, Select, Form, message} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useLangContext } from '../../libs/utils/context'
import { userInfo, msgList,msgDetail,msgRead } from "../../libs/api"
import './index.scss'
import { removeToken } from "../../middleware/auth"

const { Option } = Select;

const Myheader = () => {
    // const [lang, changeLang] = useState('zh-cn');
    let _language = localStorage.getItem('language') || 'zh-cn';
    const [lang, changeLang] = useState(_language);
    const { setLang, langConfig } = useLangContext();
    const [memberInfo, setMemberInfo] = useState({})
    const [keyword, setKeyword] = useState("")
    const history = useHistory();
    const handleLogout = () => {
        localStorage.removeItem("userType")
        removeToken()
        history.push("/")
    }

    useEffect(() => {
        setLang(lang)
    }, [lang])

    const [topMsgList, setTopMsgList] = useState([])
    const [topMsgTotal, setTopMsgTotal] = useState([])
    useEffect(() => {
        userInfo().then(res => {
            if (res.code === 200) {
                setMemberInfo(res.data)
                localStorage.setItem("userType", res.data.type)
            }
        }).catch(err => { })

        getMsgList()
    }, [])
    const getMsgList = () =>{
        msgList({}).then(res => {
            if (res.code === 200) {
                setTopMsgList(res.data.normal.data)
                setTopMsgTotal(res.data.normal.count)
            }
        }).catch(err => { })
    }

    const handleSearch = () => {
        if(keyword==''){
            message.info(langConfig.search_text);
        }else {
            history.push({ pathname: "/agent/search/"+keyword, state: { keyword } })
        }

    }
    const content = (
        <div className="myusertent">
            <div className="myusertent01">{langConfig.current_acc_text}</div>
            <div className="myusertent02"><Link to={{ pathname: "/agent/member", state: { tabIndex: 0 } }}>{memberInfo.name}</Link></div>
            <div className="myusertent03"><Link to={{ pathname: `/agent/member`, state: { tabIndex: 0 } }}>{langConfig.account_info}</Link></div>
            <div className="myusertent03"><Link to={{ pathname: `/agent/member`, state: { tabIndex: 1 } }}>{langConfig.favorites}</Link></div>
            {
                memberInfo.type===1?<div className="myusertent03"><Link to={{ pathname: `/agent/member`, state: { tabIndex: 2 } }}>{langConfig.account_staff}</Link></div>:null
            }
            <div className="myusertent04" onClick={handleLogout}>{langConfig.logout}</div>
        </div>
    );

    const setIsRead = (id,is_open) =>{
        // window.open('/user/123')
        msgDetail({mess_id:id}).then(res => {
            if (res.code === 200) {
                console.log('resres',res)
                if(is_open == 1){
                    if (["PNG", 'JPG', 'JPEG', 'GIF', 'PDF'].includes(res.data.mime)) {
                        // return (<span className="td-pc td-pcimg"><Link to={`/fileDetail/?type=${item.type}&url=${item.url}`} target="_blank" title={item.file_name}>{item.file_name}</Link></span>)
                        window.open(`/fileDetail/?type=${res.data.mime}&url=${res.data.url}`)
                    } else {
                        window.open(`${res.data.url}`)
                    }
                }
                getMsgList()
            }
        }).catch(err => { })
    }

    // 标记全部已读
    const msgReadAll = () => {
		if(topMsgList.length > 0){
			let _ids = []
			topMsgList.forEach(item=>{
				_ids.push(item.mess_id)
			})
			msgRead({mess:JSON.stringify(_ids)}).then(res => {
				if (res.code === 204) {
					getMsgList()
				}
			}).catch(err => { })
		}
		
		setModalVisible(false)
	}

    //2022-04-02 新增弹窗 ↓↓
    const tidingsTent = () => {
        return (<div className="tidings-Tent">
            <div className="tidings-tle popmsgtitle"><div>{langConfig.Notification}</div> <div onClick={()=>msgReadAll()} className="msg-read-all">{langConfig.read_all}</div></div>
            <ul className="tidings-box">
                {
                    topMsgList.map(item => {
                        return (
                            // <Link to="" className="tidings-box-li" key={item.mess_id}>
                            //     <div className="tidings-box-left">
                            //         <p className="tidings-box-nr">{item.title}</p>
                            //         <p className="tidings-box-pr">{item.create_time}</p>
                            //     </div>
                            //     <span className="tidings-box-right">详情 ></span>
                            // </Link>
                            <li to="" className="tidings-box-li" key={item.mess_id}>
                                <div className="tidings-box-left" style={{cursor:"pointer",width:"calc(100% - 50px)"}} onClick={()=>setIsRead(item.mess_id,1)}>
                                    <p className="tidings-box-nr">{item.title}</p>
                                    <p className="tidings-box-pr">{item.create_time}</p>
                                </div>
                                <span className="tidings-box-right" onClick={()=>setIsRead(item.mess_id,2)}>{langConfig.readFlag}</span>
                            </li>
                        )
                    })
                }

            </ul>
        </div>)
    }
    //2022-04-02 新增弹窗 ↑↑


	const [modalVisible, setModalVisible] = useState(true)
	const closeModal = () =>{
		setModalVisible(false)
	}

    return (
        <>
            <div className="header-wraper">
                <div className="header">
                    <Link to="/agent/dashboard"><img src={logo} className="my-logo" alt="logo" /></Link>
                    <Form className="search-wraper">
                        <input
                            className="search"
                            type="text"
                            placeholder={langConfig.search_text}
                            onChange={(e) => { setKeyword(e.target.value) }}
                        />
                        <button className="btn" onClick={() => handleSearch()}>{langConfig.search_btn_text}</button>
                    </Form>
                    <div className="myuser">
                        <span className="myuser-avatar-item avatar-item">
                            {/* 2022-04-02 新增弹窗 ↓↓ */}
                            <Popover placement="bottomRight" content={tidingsTent()} >
                                <div className="tidings-btn-on">
                                    {/* <Badge className="tidings-btn" count={topMsgList.length}>
                                        <Avatar shape="square" icon={<UserOutlined />} />
                                    </Badge> */}
                                    <Badge className="tidings-btn" count={topMsgTotal}>
                                        <Avatar shape="square" icon={<UserOutlined />} />
                                    </Badge>
                                </div>
                            </Popover>
                            {/* 2022-04-02 新增弹窗 ↑↑ */}
                        </span>
                        <div className="myuserid">
                            <Popover content={content} placement="bottom" trigger="hover">
                                <img className="myuseridpct" alt="" style={{borderRadius:"50%"}} src={memberInfo.head} />
                            </Popover>
                            <div className="language-lect">
                                {/* { langConfig.name} */}
                                <Select onChange={(e) => { changeLang(e); window.location.reload() }} value={lang} dropdownMatchSelectWidth={false} >
                                    <Option value="zh-cn"><span className="lg-zh">中 文</span></Option>
                                    <Option value="en-us"><span className="lg-en">English</span></Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{height:"90px"}}></div>

            
        </>
    )
}
export default Myheader