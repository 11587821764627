import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Pagination, Select, Input, Checkbox, message, Form, Button } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined,SearchOutlined } from '@ant-design/icons';
import Query from '../../components/query/Query';
import "./index.scss"
import { moduleBasic, moduleDataList, isFavorites, downloadFileZip } from "../../libs/api"
import { downLoadFile } from "../../libs/utils/function"
import { useLangContext } from '../../libs/utils/context'

const { Option } = Select;

function Channels(props) {
    const [curDisplayWay, setCurDisplayWay] = useState("list") // grid list 网格、列表视图
    const [form] = Form.useForm();
    const changeDisplayWay = (val) => {
        setCurDisplayWay(val)
    }
    let _language = localStorage.getItem('language') || 'zh-cn';
    const [lang, changeLang] = useState(_language);
    const { setLang, langConfig } = useLangContext();
    useEffect(() => {
        setLang(lang)
    }, [lang])

    const { folder_id } = props.match.params
    const [pageSource, setPageSource] = useState({ total: 0 })
    const [dataSource, setDataSource] = useState([])
    const [folderList, setFolderList] = useState([])
    const [pagination, setPagination] = useState({ current: 1, pageSize: 16 })
    const [sortWay, setSortWay] = useState(1) // 默认降序
    const [displayWay, setDisplayWay] = useState("list") // grid list
    const [navInfo, setNavInfo] = useState({})
    const [formParams, setFormParams] = useState([])
    const [formQuery, setFormQuery] = useState({})
    const [formFolderId, setFormFolderId] = useState(folder_id)
    const [checkAll, setCheckAll] = useState(false)

    useEffect(() => {
        getBasic()
    }, [])
    useEffect(() => {
        getDataSource()
    }, [pagination, formQuery, formFolderId])


    const [initialValObj, setInitialValObj] = useState({})
    // Banner图及筛选条件
    const getBasic = () => {
        moduleBasic({ folder_id }).then(res => {
            if (res.code === 200) {
                setNavInfo({ title:lang=='zh-cn'?res.data.zh:res.data.en, banner: res.data.banner })
                let _formParams = res.data.search_key;
                let tmpKey;
                let _initialValObj = {}
                _formParams.forEach(item=>{
                    tmpKey= item.key;
                    if(item.type === "select"){
                        _initialValObj[tmpKey] = ""
                    }else{
                        _initialValObj[tmpKey] = ""
                    }
                })
                setInitialValObj(_initialValObj)
                setFormParams(_formParams)
            }
        }).catch(err => { })
    }
    const getDataSource = () => {
        moduleDataList({ ...formQuery,  page: pagination.current, limit: pagination.pageSize, order: sortWay, folder_id: formFolderId }).then(res => {
            if (res.code === 200) {
                let _data = res.data
                _data.forEach((item, index) => {
                    _data[index].checked = false;
                })

                setPageSource({ total: res.count })
                setFolderList(res.folder)
                setDataSource(_data)
            }
        }).catch(err => { })
    }
    const onPageChange = (page, pageSize) => {
        setCheckAll(false)
        setPagination({ current: page, pageSize })
    }

    const onShowSizeChange = (current, size) => {
        setPagination({ current: 1, pageSize: size })
    }
    const handleSort = (sort) => {
        setSortWay(sort)
        getDataSource()
    }

    const handleDisplayWay = (val) => {
        setDisplayWay(val)
    }
    const handleFormQuery = (obj) => {
        if (pagination.current !== 1) {
            setPagination({ ...pagination, current: 1 })
        } else {
            let formres = form.getFieldsValue();
            setFormQuery({ ...formres })
        }
    }

    const doFavorites = (val) => {
        isFavorites({ file_id: val }).then(res => {
            if (res.code === 204) {
                getDataSource()
            }
            message.info(res.msg)
        }).catch(err => { })
    }

    // 全选或反选
    const onCheckChange = (e) => {
        setCheckAll(e.target.checked)
        // console.log(e.target.checked)
        let mydata = dataSource
        mydata.forEach((item, index) => {
            mydata[index].checked = e.target.checked
        })
        setDataSource([...mydata])
    }
    const onItemChange = (index) => {
        let mydata = dataSource
        mydata[index].checked = !mydata[index].checked
        setDataSource([...mydata])
    }

    const handleDownLoad = () => {
        let mydata = dataSource
        let _len = 0
        let _file_id = [];
        mydata.forEach((item, index) => {
            if (mydata[index].checked) {
                _len += 1
                _file_id.push(item.file_id)
            }
        })
        if (_len === 0) {
            message.error(langConfig.c_download_msg);
            return false;
        }
        downloadFileZip({ file: JSON.stringify(_file_id) }).then(res => {
            if (res.code === 200) {
                var i = 1;
                for (const item of res.data) {
                    // downLoadFile(item);
                    // window.open(item, '_blank');
                    var hiddenIFrameID = 'hiddenDownloader' + i++;
                    var iframe = document.createElement('iframe');
                    iframe.id = hiddenIFrameID;
                    iframe.style.display = 'none';
                    document.body.appendChild(iframe);
                    iframe.src = item;
                }
            } else {
                message.info(langConfig.msg_net_error);
            }

        }).catch(err => { })
    }

    const listFileType = (item) => {
        if (["PNG", 'JPG', 'JPEG', 'GIF'].includes(item.type)) {
            return (<span className="td-pc td-pcimg"><Link to={`/fileDetail/?type=${item.type}&url=${item.url}`} target="_blank" title={item.file_name}>{item.file_name}</Link></span>)
        } else if (["MP4", 'MOV'].includes(item.type)) {
            return (<span className="td-pc td-pcvedio"><Link to={`/fileDetail/?type=${item.type}&url=${item.url}`} target="_blank" title={item.file_name}>{item.file_name}</Link></span>)
        } else if (["PDF"].includes(item.type)) {
            return (<span className="td-pc td-pctest"><Link to={`/fileDetail/?type=${item.type}&url=${item.url}`} target="_blank" title={item.file_name}>{item.file_name}</Link></span>)
        } else {
            return (<span className="td-pc td-pctest"><a href={`${item.url}`} target="_blank" title={item.file_name}>{item.file_name}</a></span>)
        }

    }

    const renderGridItem = (item, index) => {
        if (["XLSX", "XLS", 'DOC', 'DOCX', 'PPTX', 'STL', 'ZIP', 'AI'].includes(item.type)) {
            return (<li className="list-sntems" key={item.file_id}>
                {
                    item.is_favorites === 1 ? <span style={{ position: "absolute", left: "10px", top: "10px", zIndex: 100 }} className="td-collection td-collectionon" onClick={() => doFavorites(item.file_id)}>&nbsp;</span> : <span style={{ position: "absolute", left: "10px", top: "10px", zIndex: 100 }} className="td-collection td-collectionof" onClick={() => doFavorites(item.file_id)}>&nbsp;</span>
                }
                <div className="list-box">
                    <a href={item.url} target="_blank" rel="noreferrer" title={item.file_name}><img className="list-img" title={item.file_name} src={item.cover} alt="" />
                    </a>
                    <span className="list-pdf">{item.type}</span>
                </div>
                <div className="snlist-title"><Checkbox onChange={() => onItemChange(index)} checked={item.checked}></Checkbox> &nbsp;{item.file_name}
                    <div style={{ float: "right" }}>
                        {
                            item.is_favorites === 1 ? <span className="td-collection td-collectionon" onClick={() => doFavorites(item.file_id)}>&nbsp;</span> : <span className="td-collection td-collectionof" onClick={() => doFavorites(item.file_id)}>&nbsp;</span>
                        }</div>
                </div>
            </li>)
        } else {
            return (<li className="list-sntems" key={item.file_id}>
                {
                    item.is_favorites === 1 ? <span style={{ position: "absolute", left: "10px", top: "10px", zIndex: 100 }} className="td-collection td-collectionon" onClick={() => doFavorites(item.file_id)}>&nbsp;</span> : <span style={{ position: "absolute", left: "10px", top: "10px", zIndex: 100 }} className="td-collection td-collectionof" onClick={() => doFavorites(item.file_id)}>&nbsp;</span>
                }

                <div className="list-box">
                    <Link to={`/fileDetail/?type=${item.type}&url=${item.url}`} target="_blank" title={item.file_name}><img className="list-img" src={item.cover} alt="" />
                    </Link>
                    <span className="list-pdf">{item.type}</span>
                </div>
                <div className="snlist-title" title={item.file_name}><Checkbox onChange={() => onItemChange(index)} checked={item.checked}></Checkbox> &nbsp;{item.file_name}

                </div>
            </li>)
        }
    }

    const calcFileSize = (val) => {
        let size = Math.round(val / 1024 * 100) / 100
        // if(size>=1024){
        //     size = Math.round(size/1024*100)/100   
        //     return size+"MB"
        // }
        return size + "MB"
    }


    const renderMyFormFields = () => {
        return formParams.map((item, index) => {
            switch (item.type) {
                case "select":
                    return (
                        <Form.Item className="SnSelect-style" label={item.lable} name={item.key} key={`p${index}`} >
                            <Select placeholder="" allowClear style={{width:"125px"}}>
                                {
                                    item.options.map((sonItem, sonKey) => {
                                        return <Option value={sonItem.value} key={`s${sonKey}`}>{sonItem.label}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    )
                case "text":
                    return (
                        <Form.Item className="SnSpace-style" name={item.key} key={`p${index}`}>
                            <Input placeholder={item.lable}  />
                        </Form.Item>
                    )
                default:
                    return null;
            }
        })
    }


    return (
        <>
            {/* Banner */}
            <div className="subpage-banner" style={{ backgroundImage: `url(${navInfo.banner})` }}>
                <div className="subpage-tent">
                    <div className="subpage-tle">{langConfig.n_digital_assets}</div>
                    <div className="subpage-rln">{langConfig.postion}：<span className="subpage-index"><Link to="/agent/dashboard" className='a-white'>{langConfig.home}</Link></span> - {navInfo.title}</div>
                </div>
            </div>

            {/* <Query formParams={formParams} handleDisplayWay={handleDisplayWay} handleFormQuery={handleFormQuery} >{navInfo.title}</Query> */}


            {
                formParams && formParams.length > 1? <div className="bread-box">
                <div className="bread-box-left-form">
                    <div>
                        <Form form={form} layout="inline" initialValues={{...initialValObj}}>
                            {
                                renderMyFormFields()
                            }
                            <Form.Item>
                                <Button icon={<SearchOutlined />} onClick={() => handleFormQuery()} ></Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className="bread-box-right-noform">
                    <div className="SnSearch-right">
                        <button className={curDisplayWay==="grid"?"list-pcton":"list-pct"} onClick={() => { changeDisplayWay('grid') }}></button>
                        <button className={curDisplayWay==="list"?"list-tston":"list-tst"}  onClick={() => { changeDisplayWay('list') }}></button>
                    </div>
                </div>
            </div>:<div className="bread-box">
                <div className="bread-box-left">
                    <div className='bread-item'><a href="/agent/dashboard">{langConfig.home}</a></div>
                    {
                        folderList.parent && folderList.parent.map(item => {
                            return <div className='bread-item' key={item.folder_id}><span onClick={() => { setFormFolderId(item.folder_id) }}>{item.title}</span></div>
                        })
                    }
                </div>
                <div className="bread-box-right">
                    <Form form={form} layout="inline" >
                        <Form.Item className="SnSpace-style" name='keyword'>
                            <Input className='input-keyword' placeholder={langConfig.digital_search}  />
                        </Form.Item>
                        <Form.Item>
                            <Button className='digital-search-btn' icon={<SearchOutlined />} onClick={() => handleFormQuery()} ></Button>
                        </Form.Item>
                    </Form>
                    <div className="SnSearch-right">
                        <button className={curDisplayWay==="grid"?"list-pcton":"list-pct"} onClick={() => { changeDisplayWay('grid') }}></button>
                        <button className={curDisplayWay==="list"?"list-tston":"list-tst"}  onClick={() => { changeDisplayWay('list') }}></button>
                    </div>
                </div>
            </div>
            }
            


            



            <div className="snlist-box">
                <div className='table-info-title'>
                    <div className='info-title-count'>{langConfig.total} <span>{pageSource.total}</span></div>
                    <div className='info-title-sort' >
                        {langConfig.d_sort_time} {sortWay === 1 ? <ArrowUpOutlined onClick={() => handleSort(2)} /> : <ArrowDownOutlined onClick={() => handleSort(1)} />}
                    </div>
                    <div className='info-title-down'>
                        <Checkbox onChange={onCheckChange} checked={checkAll}></Checkbox> &nbsp;<img src='../../assets/screen/dload.png' alt='' /> <span style={{ cursor: "pointer" }} onClick={handleDownLoad}>{langConfig.c_download}</span>
                    </div>
                </div>

                <div className="snlist-list">
                    {
                        curDisplayWay === "grid" ? (
                            <ul className="snlist-ul">
                                {
                                    folderList.child && folderList.child.map(item => {
                                        return <li className="list-sntems" key={item.folder_id} onClick={() => { setFormFolderId(item.folder_id) }} style={{ cursor: "pointer" }}>
                                            <div className="list-box">
                                                <img className="list-img" src='/assets/folder_big.png' alt="" />
                                            </div>
                                            <div className="snlist-title">{item.title}</div>
                                        </li>
                                    })
                                }
                                {
                                    dataSource.map((item, index) => {
                                        return (
                                            renderGridItem(item, index)
                                        )
                                    })
                                }
                            </ul>
                        ) : (
                            <>
                                <table className="snltsgle-table">
                                    <thead className="snltsgle-thead">
                                        <tr>
                                            <th className="th-with01">{langConfig.digital_title}</th>
                                            {/*<th className="th-with02">{langConfig.digital_publish}</th>
                                            <th className="th-with03">{langConfig.digital_file_type}</th>*/}
                                            <th className="th-with04">{langConfig.digital_file_size}</th>
                                            <th className="th-with05">{langConfig.digital_upload_time}</th>
                                            <th className="th-with06"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="tb-td-border">
                                        {
                                            folderList.child && folderList.child.map(item => {
                                                return(
                                                    <tr key={item.folder_id}>
                                                    <td className="tb-td-color01 th-with01">
                                                        <div className="table-folder-box" onClick={() => { setFormFolderId(item.folder_id) }}>
                                                        <img src='/assets/folder_big.png' style={{width:"16px", height:'16px'}} alt="" />&nbsp;{item.title}
                                                        </div>
                                                    </td>
                                                    <td className="tb-td-color01 th-with04">-
                                                    </td>
                                                    <td className="tb-td-color02 th-with05">-</td>
                                                    <td className="tb-td-color03 th-with06">-</td>
                                                </tr>
                                                )
                                            })
                                        }
                                        {
                                            dataSource.map((item, index) => {
                                                return (
                                                    <tr key={item.file_id}>
                                                        <td className="tb-td-color01 th-with01">
                                                            <Checkbox onChange={() => onItemChange(index)} checked={item.checked}></Checkbox> &nbsp;{listFileType(item)}</td>
                                                        {/* <td className="tb-td-color01 th-with02">RAISE3D</td>
                                                        <td className="tb-td-color01 th-with03"><span>{item.type}</span></td>*/}
                                                        <td className="tb-td-color01 th-with04">{
                                                            calcFileSize(item.size)
                                                        }
                                                        </td>
                                                        <td className="tb-td-color02 th-with05">{item.create_time}</td>
                                                        {
                                                            item.is_favorites === 1 ? <td className="tb-td-color03 th-with06"><span className="td-collection td-collectionon" onClick={() => doFavorites(item.file_id)}>{langConfig.d_remove_fav}</span></td> : <td className="tb-td-color03 th-with06"><span className="td-collection td-collectionof" onClick={() => doFavorites(item.file_id)}>{langConfig.d_add_fav}</span></td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </>
                        )
                    }
                </div>

                <div className="list-page">
                    <Pagination
                        size="small"
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={pageSource.total}
                        onChange={(page, pageSize) => onPageChange(page, pageSize)}
                        pageSizeOptions={[16, 32, 64]}
                        onShowSizeChange={(current, size) => onShowSizeChange(current, size)}
                    />
                </div>
            </div >
        </>

    )
}

export default Channels