import React, {useEffect, useRef} from 'react'

function FileDetail(props) {
    const vedioRef = useRef(null)
    let search = props.location.search;
    const paramsString = search.substring(1)
    const paramsArr = paramsString.split("&url=")
    console.log('paramsArr',paramsArr)
    let typeArr = paramsArr[0].split("=")
    // let urlArr = paramsArr[1].split("=")
    let url = paramsArr[1]

    useEffect(() => {
        if (["MP4"].includes(typeArr[1])) {
            setTimeout(() => {
                vedioRef.current.play();
            }, 200)
        }
    }, [])
    const renderTar = () => {
        if (["MP4", 'MOV'].includes(typeArr[1])) {
            // muted
            return (<video controls="controls" src={url} ref={vedioRef} autoplay="autoplay" preload style={{
                height: "100%",
                overflow: "hidden",
                width: "100%"
            }}>
                您的浏览器不支持 video 标签。
            </video>)
        } else if (["PDF"].includes(typeArr[1])) {
            return <embed src={url} type="application/pdf" width="100%" style={{height: "100vh"}}></embed>
        } else if (["PNG", 'JPG', 'JPEG', 'GIF'].includes(typeArr[1])) {
            return <div style={{
                display: "flex",
                background: "#000000",
                alignItems: "center",
                height: "100%",
                justifyContent: "center"
            }}><img src={url} alt="" style={{width:"auto",maxWidth:"100%"}}/>
            </div>
        } else {
            window.open(url)
        }
    }
    return (
        <div className='file-detail-box' style={{
            display: "flex",
                background: "#000000",
                alignItems: "center",
                minHeight: "100%",
                justifyContent: "center",
                overflow:"scroll"
        }}>
            {renderTar()}
        </div>
        // <>
        // {renderTar()}
        // </>
    )
}

export default FileDetail