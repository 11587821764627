import React, { useCallback, useEffect, useRef, useState } from 'react'
import { examResult } from "../../../../libs/api"
import { useLangContext } from '../../../../libs/utils/context'
import { Button, message } from 'antd';

function Result(props) {
    let _language = localStorage.getItem('language') || 'zh-cn';
    const [lang, changeLang] = useState(_language);
    const { setLang, langConfig } = useLangContext();
    useEffect(() => {
        setLang(lang)
    }, [lang])


    const { topic } = props
    const modalRef = useRef(0);
    const [isFullScreen, setIsFullScreen] = useState(true) // 默认是全屏
    const [dataList, setDataList] = useState({}) // 试题列表
    const [dataItem, setDataItem] = useState({}) // 当前试题数据
    const [result, setResult] = useState({}) // 考试结果
    const [modalVisible, setModalVisible] = useState(false)
    const [curPaperNo, setCurPaperNo] = useState(1)
    useEffect(() => {
        examResult({ topic }).then(res => {
            let analysis = []
            res.data.analysis.forEach(item => {
                analysis.push({ ...item, trues: JSON.parse(item.trues), answer: JSON.parse(item.answer) })
            })

            setDataList(analysis)
            setResult({ result: res.data.result, score: res.data.score })
        }).catch(err => {

        })
    }, [])

    // 获取题目
    const getPaperData = (index) => {
        let _item = dataList[index - 1];
        setDataItem({ ..._item })
    }

    // 上一题
    const handlePre = () => {
        let _curPaperNo = curPaperNo - 1
        if (_curPaperNo > 1) {
            setCurPaperNo(_curPaperNo)
            getPaperData(_curPaperNo)
        } else {
            setCurPaperNo(1)
            message.info(langConfig.test_first)
        }

    }

    // 下一题
    const handleNext = () => {
        let _curPaperNo = curPaperNo + 1
        if (_curPaperNo <= dataList.length) {
            setCurPaperNo(_curPaperNo)
            getPaperData(_curPaperNo)
        } else {
            message.info(langConfig.test_last)
        }
    }

    const showAnalysis = () => {
        modalRef.current = new Date().valueOf()
        getPaperData(1);
        setModalVisible(true)
    }

    const closeAnalysis = () => {
        setCurPaperNo(1)
        setModalVisible(false)
    }

    const examType = (index) => {
        let types = [langConfig.test_danxt, langConfig.test_duoxt, langConfig.test_pdt]
        return types[index - 1];
    }

    const renderResult = () => {
        if (dataItem.type === 1 || dataItem.type === 2) {
            return (
                <>
                    <li className="result-items"> {/* result-items-right */}
                        <p className="result-items-p">A：{dataItem.option_a}</p>
                        {dataItem.option_a_img!==""?<img className="result-items-img" alt="" src={dataItem.option_a_img} />:''}
                    </li>
                    <li className="result-items">
                        <p className="result-items-p">B：{dataItem.option_b}</p>
                        {dataItem.option_b_img!==""?<img className="result-items-img" alt="" src={dataItem.option_b_img} />:''}
                    </li>
                    <li className="result-items">{/* result-items-wrong*/}
                        <p className="result-items-p">C：{dataItem.option_c}</p>
                        {dataItem.option_c_img!==""?<img className="result-items-img" alt="" src={dataItem.option_c_img} />:''}
                    </li>
                    <li className="result-items">
                        <p className="result-items-p">D：{dataItem.option_d}</p>
                        {dataItem.option_d_img!==""?<img className="result-items-img" alt="" src={dataItem.option_d_img} />:''}
                    </li>
                </>
            )
        }else if (dataItem.type === 3) {
            return (
                <>
                    <li className="result-items"> {/* result-items-right */}
                        <p className="result-items-p">E：{langConfig.test_true}</p>
                        {dataItem.option_a_img!==""?<img className="result-items-img" alt="" src={dataItem.option_a_img} />:''}
                    </li>
                    <li className="result-items">
                        <p className="result-items-p">F：{langConfig.test_false}</p>
                        {dataItem.option_b_img!==""?<img className="result-items-img" alt="" src={dataItem.option_b_img} />:''}
                    </li>
                </>
            )
        }
    }

    //退出全屏
	const exitFullscreen = () => {
        try{
            setIsFullScreen(false)
            console.log('exitFullscreen')
            let de = document;
            if (document.fullscreenElement && de.exitFullscreen) {
                de.exitFullscreen();
            } else if (document.fullscreenElement && de.mozCancelFullScreen) {
                de.mozCancelFullScreen();
            } else if (document.fullscreenElement && de.webkitCancelFullScreen) {
                de.webkitCancelFullScreen();
            }
        }catch(e){

        }
        
	}
    return (
        <>
            <div className="exam-result">
                <div className="exam-result-tle">{result.result === 1 ? langConfig.test_kstg :langConfig.test_kswtg}</div>
                <div className="exam-result-on">{result.score}</div>
                <div className="exam-result-p">{langConfig.test_chengji}</div>
                <div className="btn-exam-result-bg">
                    <button className="btn-exam-result" onClick={showAnalysis}>{langConfig.test_lookjx}</button>

                      
                </div>
                {
                    isFullScreen?<div style={{marginTop:"20px"}}>
                    <Button onClick={()=>exitFullscreen()}>{langConfig.test_exit_fullscreen}</Button>    
                    </div>:null
                }
			          
            </div>
            {/* 弹窗地址  style={{display:"none"}} */}
            <div className="result-wraper" style={{ display: modalVisible ? "block" : "none" }}></div>
            <div className="result-box" style={{ display: modalVisible ? "block" : "none" }}>
                <div className="result-box-close" onClick={closeAnalysis}></div>
                <div className="result-nme">{langConfig.test_dajx}</div>
                <div className="result-title">{langConfig.test_process}(<span>{curPaperNo}/{dataList.length}</span>)</div>
                <div className="option-questions-tle">
                    <span>{curPaperNo}.</span>
                    <span className="single-choice">【{examType(dataItem.type)}】</span>
                    <span>{dataItem.question}（{dataItem.score} {
                    // langConfig.score
                    }）</span>
                </div>
                <ul className="result-subject">
                    {
                        renderResult()
                    }
                </ul>
                <div className="result-answer">{langConfig.test_ndda}：{dataItem.answer}  {langConfig.test_zqda}：{dataItem.trues}</div>
                <div className="result-answer-p">{langConfig.test_dajx}：{dataItem.analysis}</div>
                <button className="btn-answer btn-answer-left" onClick={handlePre}>{langConfig.test_syt}</button>
                <button className="btn-answer" onClick={handleNext}>{langConfig.test_xyt}</button>
            </div>
        </>
    )
}

export default Result