export const langList = {
    'zh-cn': {
        //一些提示
        msg_net_error:'网络错误，请稍候重试',

        // 用户登录页面
        sys_title: '欢迎登录Raise3D销售伙伴平台',
        login_email_text:'请输入邮箱',
        login_pwd_text:'请输入密码',
        login_yzm_text: '请输入验证码',
        login_btn_text :'立即登录',
        forget_pwd:'忘记密码',

        // 忘记密码
        send_yzm:"发送验证码",
        enter_pwd_again:"请再次输入密码",
        confirm_modify:"确认修改",
        back_to_login:"返回登录",

        input_password:"请输入原密码",
        input_password_a:"新密码不得低于6位数",
        input_password_b:"两次密码输入不一致",

        // 激活账号
        ac_title:"激活账号",
        ac_field_email:"请输入邮箱",
        ac_field_code:"请输入验证码",
        ac_field_pwd:"请输入新的密码",
        ac_field_repwd:"请再次输入新的密码",


        // 顶部导航
        current_acc_text:"当前账号",
        favorites:'收藏夹',
        logout:'退出登录',
        postion:"当前位置",
        search_btn_text:"搜索",
        home:"首页",
        filters:"筛选",
        search_text:"请输入关键词进行搜索",
        Notification:"消息通知",
        read_all:"全部已读",
        readFlag:'已读',
        recent_update:"近期更新",

        // 个人中心
        personal:"个人中心",
        account_info:"账号信息",
        account_staff:"我的成员",

        p_acc:'账号',
        p_company:'公司',
        p_job:'职位',
        p_name:'姓名',
        p_email:'邮箱',
        p_pwd:'密码',
        p_click_mod:'点击修改',
        p_mod_pwd:"修改密码",
        p_new_pwd:"请输入新的密码",
        btn_cancel:"取消",
        btn_ok:"确定",

        total:"总数量",
        belongs_cate:"所属分类",
        look:"查看",
        remove:"移除",
        setting:"课程设置",
        apply_add_staff:"申请增加成员",
        comfirm_del:"确认删除吗？",

        p_member_name:'请填写成员姓名',
        p_member_pos:'请填写成员职位',
        p_member_add:'请填写成员邮箱',

        digital_search:'搜索关键词',
        digital_title:'标题',
        digital_publish:'发布人',
        digital_file_type:'文件类型',
        digital_file_size:'文件大小',
        digital_upload_time:'上传时间',

        // 课程状态
        partner:'销售伙伴学院',
        staff:"成员",
        check_all:"全选",
        all_course:"全部课程",
        open_course:"开放课程",
        close_course:"关闭课程",
        c_open_item:"展开",
        c_close_item:"收起",
        c_course:"全部课程",
        c_study:"学习中",
        c_complete:"已完成",
        c_test:"考试",
        c_cert:"证书",

        c_course_name:'课程名称',
        c_min_study_time:'最低学习时间',
        c_studyed_time:'已学习时间',
        c_close_date:'(课程/考试)截止日期',
        c_enter_test:'进入考试',
        c_exam:'考试',
        c_exam_time:'考试时间',
        c_exam_status:'考试状态',
        c_make_up:'补考次数',
        c_open_retest:'开放重考',
        c_duration:'时长',
        c_cert_name:'证书名称',
        c_get_date:'颁发日期',
        c_expire_date:'失效日期',
        c_download:'下载',
        c_download_msg:'请选择要下载的文件',

        c_status:"状态",
        c_status_progress_no:"未学习",
        c_status_progressing:"学习中",
        c_status_completed:"已完成",
        c_status_test:"考试",

        min_learning_time:'最短学习时间',
        start_learning:'开始学习',
        Return:"返回上一级",

        // assets
        d_sort_time:"按时间排序",
        d_add_fav:"加入收藏",
        d_remove_fav:"取消收藏",

        n_digital_assets:'资料',
        n_digital_trade:'打印模型',

        //
        test_process:'考试进度',
        test_pdt:'判断题',
        test_duoxt:'多选题',
        test_danxt:'单选题',
        test_djs:'倒计时',
        test_skip:'跳过',
        test_mark:'标记',
        test_ok:'确定',
        test_true:'正确',
        test_false:'错误',
        test_chengji:"考试成绩",
        test_lookjx:"点击查看答案解析",
        test_dajx:"答案解析",
        test_ndda:"您的答案",
        test_zqda:"正确答案",
        test_syt:"上一题",
        test_xyt:"下一题",
        test_kstg:"恭喜您，已通过本次考试！",
        test_kswtg:"很遗憾，本次考试未通过！",
        test_check:'绿色为已选择，黄色为标记（可回去检查、更改），红色为未选择',
        test_weidati:'您还未答题，如暂不答，请选择跳过后续再答！',
        makeup_ok:'标记成功',
        test_passed:'通过',
        test_failed:'未通过',
        test_makeup:'待补考',
        test_first:'"已经是第一题了！"',
        test_last:'已经是最后一题了！',
        test_exit_fullscreen:'点击退出全屏',
        score:'分',

    },
    'en-us': {
        msg_net_error:'Network Error, Please Try Again Later',
        sys_title: <>
            <p style={{margin: 0}}>Welcome to Raise3D</p>
            <p style={{marginBottom: 20}}>Sales Partner Portal</p>
        </>,
        login_email_text:'Please enter your email',
        login_pwd_text:'Please enter your password',
        login_yzm_text: 'Verification code',
        login_btn_text :'Sign in',
        forget_pwd:'Forget password',

        // 忘记密码
        send_yzm:"Send code",
        enter_pwd_again:"Please re-enter your password",
        confirm_modify:"Comfirm",
        back_to_login:"Back to login",

        input_password:"Please Enter The Original Password",
        input_password_a:"The New Password Must Not Be Less Than 6 Digits",
        input_password_b:"The Two Passwords Are Inconsistent",

        ac_title:"Account activation",
        ac_field_email:"Please enter your email",
        ac_field_code:"Please enter code",
        ac_field_pwd:"Please enter your password",
        ac_field_repwd:"Please re-enter your password",

        // 顶部导航
        current_acc_text:"Account",
        favorites:'Favorites',
        logout:'Logout',
        search_text:"Please Enter Keyword To Search",
        search_btn_text:"Search",
        Notification:"Notification",
        read_all:"Mark AII Read",
        readFlag:'OK',
        recent_update:"Recent updates",

        // 个人中心
        personal:"Profile",
        account_info:"Account",
        account_staff:"Member",
        postion:"Position",
        home:"Home",
        filters:"Filter",

        p_acc:'Account',
        p_company:'Company',
        p_job:'Job',
        p_name:'Name',
        p_email:'Email',
        p_pwd:'Password',
        p_click_mod:'Edit',
        p_mod_pwd:"Edit Password",
        p_new_pwd:"input your new password",
        btn_cancel:"Cancel",
        btn_ok:"Submit",

        total:"Total",
        belongs_cate:"Belongs Category",
        look:"Check",
        remove:"Remove",
        setting:"Setting",
        apply_add_staff:"Add Member",
        comfirm_del:"comfirm delete ？",
        p_member_name:'Please enter member’s name',
        p_member_pos:'Please enter member’s position',
        p_member_add:'Please enter member’s email address',

        digital_title:'title',
        digital_search:'Search Keyword',
        digital_publish:'Publisher',
        digital_file_type:'File Type',
        digital_file_size:'File Size',
        digital_upload_time:'Upload Time',

        // 课程状态
        partner:'Sales Partner Academy',
        staff:"Member",
        check_all:"select all",
        all_course:"All courses",
        open_course:"Open course",
        close_course:"Close course",
        c_open_item:"Expand",
        c_close_item:"Fold",
        c_course:"All Courses",
        c_study:"Learning",
        c_complete:"Completed",
        c_test:"Tests",
        c_cert:"Certificates",

        c_course_name:'Courses Name',
        c_min_study_time:'Minimum Learning Time',
        c_studyed_time:'Learning Time',
        c_close_date:'Closing Date',
        c_enter_test:'Enter Tests',
        c_exam:'Tests',
        c_exam_time:'Tests Time',
        c_exam_status:'Tests Status',
        c_make_up:'Make-up',
        c_duration:'Duration',
        c_open_retest:'Open retest',
        c_cert_name:'Certifications',
        c_get_date:'Date of Issue',
        c_expire_date:'Expire Date',
        c_download:'Download',
        c_download_msg:'Please Select A File To Download',

        c_status:"Status",
        c_status_progress_no:"Not learning",
        c_status_progressing:"Learning",
        c_status_completed:"Completed",
        c_status_test:"Testing",

        min_learning_time:'Minimum Learning Time',
        start_learning:'Start',
        Return:"Return",

        // assets
        d_sort_time:"sort by time",
        d_add_fav:"Add favorite",
        d_remove_fav:"Remove favorite",

        n_digital_trade:'Sample Models (STLs)',
        n_digital_assets:'Digital Assets',

        test_process:'Test Progress',
        test_pdt:'True or False',
        test_duoxt:'Multiple Choice',
        test_danxt:'Single Choice',
        test_djs:'Countdown',
        test_skip:'Skip',
        test_mark:'Mark',
        test_ok:'Submit',
        test_true:'True',
        test_false:'False',

        test_chengji:"Examination results",
        test_lookjx:"Analysis",
        test_dajx:"Analysis",
        test_ndda:"Your Answer",
        test_zqda:"Correct Answer",
        test_syt:"Previous",
        test_xyt:"Next",
        test_kstg:"Congratulations! You’ve passed the test",
        test_kswtg:"Unfortunately, you did not pass the test",
        test_check:'Green indicates "Answered", Yellow indicates “Marked” (you can review to check or modify your answer), and Red indicates “Unanswered',
        score:'score',

        test_weidati:'Please answer the question！',
        test_passed:'PASSED',
        test_failed:'FAILED',
        test_makeup:'MAKE-UP',
        makeup_ok:'Marked Success',
        test_first:"It's already the first question",
        test_last:"It's the last question",
        test_exit_fullscreen:'Exit Fullscreen',
    }
}