import React, {useEffect, useState} from "react";

import {useLangContext} from '../../libs/utils/context'
import './index.css'

const Myfooter = () => {
    let _language = localStorage.getItem('language') || 'zh-cn';
    const [lang, changeLang] = useState(_language);
    const {setLang, langConfig} = useLangContext();
    useEffect(() => {
        setLang(lang)
    }, [lang])
    return (
        <>
            <div className="footer-wraper">
                <div className="footer">
                    {
                        lang === 'zh-cn' ? <>
                            ©2009-2023 上海复志信息科技股份有限公司 保留所有权利。
                            <a style={{color: "#7F7F7F",margin:"0 10px"}} href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002003541" target="_blank">
                                沪公网安备 31011002003541号
                            </a>
                            <a style={{color: "#7F7F7F",margin:"0 10px"}} href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备12042035号-5</a>
                        </> : <>
                            ©2023. All Rights Reserved. Raise 3D Technologies, Inc.
                        </>
                    }

                </div>
            </div>
        </>
    )
}

export default Myfooter