import React, { useEffect, useState } from 'react'
import { Radio } from 'antd';
import { useLangContext } from '../../../../libs/utils/context'

function  Judge(props) {
	let _language = localStorage.getItem('language') || 'zh-cn';
    const [lang, changeLang] = useState(_language);
    const { setLang, langConfig } = useLangContext();
    useEffect(() => {
        setLang(lang)
    }, [lang])


	const {item} = props
	let answer = JSON.parse(item.answer)
	function onChange(e) {
		props.handlepaperitem(e.target.value)
	}
	return (
		<div className="singlechoice-box">
			<div className="group-dan">
				<Radio.Group onChange={onChange}  defaultValue={answer[0]}>
					<Radio.Button value="e">{langConfig.test_true}</Radio.Button>
					<Radio.Button value="f">{langConfig.test_false}</Radio.Button>
					{/* ：{item.option_b} */}
				</Radio.Group>
			</div>
		</div>
	)
}

export default Judge